import React from 'react';
import { Helmet } from 'react-helmet';

import ComingSoon from 'components/ComingSoon';
import Layout from 'components/layout';

const IPhonePage: React.FC = () => (
  <Layout>
    <Helmet>
      <title>iPhone/iPad</title>
      <meta name="og:title" content="iPhone/iPad" />
      <meta name="twitter:title" content="iPhone/iPad" />
    </Helmet>
    <ComingSoon os="ios" />
  </Layout>
);

export default IPhonePage;
